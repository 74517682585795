import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { DropdownService } from 'src/app/shared/services/dropdown.service';

@Component({
  selector: 'mkt-date',
  templateUrl: './mkt-date.component.html',
  styleUrls: ['./mkt-date.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-date',
    // "(click)": '!readonly ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange"
  }
})
export class MktDateComponent implements OnInit {
  @Input() value: any;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;
  @Input() options: any = {single: true};

  public dateRangeFired = false;
  public dateValue: {startDate: any, endDate: any} = {startDate: moment(), endDate: moment()};


  formatDate = (date: any) => {
    let returnValue: any = "Sin Fecha";
    if (date) {
      if (this.options?.single) {
        returnValue = moment(date?.startDate || date).format(this.options?.formatTooltip || 'DD MMM YYYY');
      }else{
        returnValue = moment(date.startDate).format('DD MMM YYYY') +" - "+ moment(date.endDate).format('DD MMM YYYY');
      }
    }
    return returnValue;
  };
  
  public focus = false;
  private _hasChange: boolean = false;
  // public setFocus = (focus: boolean) => {this.focus = focus; setTimeout(() => {if(focus) this.input.nativeElement.focus()}, 10);}

  constructor(private dropdown: DropdownService) { 
  }

  ngOnInit(): void {
    // this.dateValue.startDate =  this.options?.single?moment((this.value?.startDate?this.value?.startDate:this.value)): moment(this.value?.startDate);
    //? Mostrar Periodo en subitems de finanzas 
    if (typeof this.value == 'string' && this.options?.format) {
      this.value = {startDate: moment(this.value, this.options?.entryFormat), endDate: moment(this.value, this.options?.entryFormat)};
    }
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}  

  public emitValue(ev: any){
    if (ev.startDate) {
      //#region validacion por issue de activacion de evento dos veces
      if (this.dateRangeFired && this.options?.single) {
        this.dateRangeFired = false;
        return;
      }
      this.dateRangeFired = true;
      //#endregion
      this.valueChange.emit(ev)
    }

    this.dropdown.closeDialog();
  }

  SelectDate(template: any, event: any){
    this.dateValue.startDate =  (this.options?.single)?moment((this.value?.startDate?this.value?.startDate:this.value)): moment(this.value?.startDate);
    this.dateValue.endDate =  (this.options?.single)?moment((this.value?.startDate?this.value?.startDate:this.value)): moment(this.value?.endDate);
    this.dropdown.openDialog(template, event, !this.options?.single ? 250 : 125);
  }
}

interface IDatePicker{startDate: any, endDate: any}