import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  public isInteger(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]+/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  public isDecimal(evt, value = null) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex =/^[\.0-9]*$/;
    var regexDecimal =/\./;
    let invalid = false;
    
    if(evt.target.value){
      invalid = evt.target.value.includes(".") && regexDecimal.test(key);
    }
    if (!regex.test(key) || invalid) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  public isNumber(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    if (theEvent.keyCode != 13) {
      key = String.fromCharCode(key);
      var regexKex = /[0-9]|\./;
      if (!regexKex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  }

  public ValuePasteIsInteger(evt){
    var AceptValues = /^[0-9]+$/;

    if(evt.match(AceptValues)){
      return parseInt(evt);
    }else{
      return false;
    }
  }

  public ValuePasteIsDecimal(evt){
    var AceptValues = /^[\.0-9]*$/;

    if(evt.match(AceptValues)){
      return parseInt(evt);
    }else{
      return false;
    }
  }

  public isAlphaNumeric(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9a-zA-Z]+/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  public noWhitespaceValidator(control: FormControl){
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }

}
