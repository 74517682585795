import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesLoadPipe } from './images-load.pipe';
import { DecryptPipe } from './decrypt.pipe';
import { statusPipe } from './status.pipe';
import { FiltroPipe } from './filtro.pipe';
import { momentPipe } from './moment.pipe';
import { InitialsPipe } from './initials.pipe';
import { selectsPipe } from './selects.pipe';
import { binnaclePipe } from './binnacle.pipe';
import { DecryptChatPipe } from './decrypt-chat.pipe';
import { RoleNamePipe  } from './role-name.pipe';
import { diffTimePipe } from './diffTime.pipe';
import { FinancesFilterPipe } from './Finances-filter.pipe';

@NgModule({
  providers: [statusPipe],
  declarations: [
    ImagesLoadPipe, 
    DecryptPipe, 
    statusPipe, 
    FiltroPipe, 
    momentPipe, 
    InitialsPipe, 
    selectsPipe,
    binnaclePipe, 
    DecryptChatPipe, 
    RoleNamePipe,
    diffTimePipe,
    FinancesFilterPipe
  ],
  imports: [],
  exports: [
    ImagesLoadPipe, 
    DecryptPipe, 
    statusPipe, 
    FiltroPipe, 
    momentPipe, 
    InitialsPipe, 
    selectsPipe, 
    binnaclePipe, 
    DecryptChatPipe, 
    RoleNamePipe,
    diffTimePipe ,
    FinancesFilterPipe
  ]
})
export class PipesModule { }
