<div class="center">
    <div class="_block cursor-pointer text-center" [class._input]="!readonly" placement="top" [ngbTooltip]="formatDate(value)" (click)="!readonly ? SelectDate(picker, $event) : {}" tooltipClass="tooltip-small">
        <ng-container *ngIf="options?.single">
            <ng-container *ngIf="options?.format">
                {{value? ((this.value.startDate?this.value.startDate:this.value) | moment:this.options?.format || 'DD MMM'):'Sin Fecha' }}
                <!-- {{this.value?.startDate | moment:this.options?.format }} -->

            </ng-container>
            <ng-container *ngIf="!options?.format">
                {{value? ((this.value.startDate?this.value.startDate:this.value) | moment:'DD MMM YYYY'):'Sin Fecha' }}
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!options?.single || false">
            {{ value? (value?.startDate | moment:'DD MMM'):"..." }} - {{ value?(value?.endDate | moment:'DD MMM'): "..." }}
        </ng-container>
    </div>
</div>

<ng-template #picker>
    <div class="mkt_dropdown mkt_col">
        <div class="head">Selecciona Fecha</div>
        <div class="content py-0">
            <app-calendar [single]="options?.single" [value]="value" (onSelect)="emitValue($event);"></app-calendar>
        </div>
    </div>
</ng-template>